import React, { useRef, useState } from 'react';

import ScrollLink from './ScrollLink';

import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMenuItem } from './Menu';
import menuStyles from './Menu.module.css';
import textStyles from './theme/text.module.css';

const menuItems: IMenuItem[] = [
  { name: 'About', id: 'about' },
  { name: 'Logistica', id: 'logistics' },
  { name: 'Premi', id: 'prizes' },
  { name: 'Giuria', id: 'jury' }
];

const MiniHackMenu = () => {
  const myTopNavRef = useRef<HTMLDivElement>(null);

  const [isHidden, setIsHidden] = useState(true);

  const hiddenClass = isHidden ? menuStyles.hidden : '';

  const handleHamburgerClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    setIsHidden(!isHidden);
  };

  return (
    <div className={menuStyles.container}>
      <div className={menuStyles.menu} id="myTopNav" ref={myTopNavRef}>
        <a
          className={`${menuStyles.icon} ${menuStyles.menuItem}`}
          onClick={handleHamburgerClick}
        >
          <FontAwesomeIcon icon={faBars} />
        </a>
        <a
          href="/"
          className={`${textStyles.textLight} ${menuStyles.menuItem} ${hiddenClass}`}
        >
          USI Hackathon
        </a>
        {menuItems.map((item, index) => (
          <ScrollLink
            key={index}
            className={`${textStyles.textLight} ${menuStyles.menuItem} ${hiddenClass}`}
            id={item.id}
          >
            {item.name}
          </ScrollLink>
        ))}
      </div>
    </div>
  );
};

export default MiniHackMenu;
