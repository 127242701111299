import React from 'react';

import { Header1, TextBold, TextSmall } from '../components/theme/text';

import juryStyles from '../sections/Jury.module.css';
import miniHackJuryStyles from './MiniHackJury.module.css';

import DefaultAvatar from '../images/jury/default.svg';
import AnaIvanchikjAvatar from '../images/mini-hackathon/jury/Ana-Ivanchikj.jpg';
import AndreaGallidabinoAvatar from '../images/mini-hackathon/jury/Andrea-Gallidabino.jpg';
import EnricoGulfiAvatar from '../images/mini-hackathon/jury/Enrico-Gulfi.jpeg';
import MonicaLandoniAvatar from '../images/mini-hackathon/jury/Monica-Landoni.png';
import SilviaGaspariniAvatar from '../images/mini-hackathon/jury/Silvia-Gasparini.jpg';
import StefanoPerregriniAvatar from '../images/mini-hackathon/jury/Stefano-Perregrini.jpg';

interface IJuryMember {
  name: string;
  surname: string;
  role?: string;
  avatar?: string;
}

const juryMembers: IJuryMember[] = [
  {
    name: 'Enrico',
    surname: 'Gulfi',
    role: 'Direttore Divisione Informatica, Città di Lugano',
    avatar: EnricoGulfiAvatar
  },
  {
    name: 'Silvia',
    surname: 'Gasparini',
    role: 'Communication & Media Manager, Lugano Region',
    avatar: SilviaGaspariniAvatar
  },
  {
    name: 'Ana',
    surname: 'Ivanchikj',
    role: 'Assistente dottoranda - Istituto del software, USI',
    avatar: AnaIvanchikjAvatar
  },
  {
    name: 'Andrea',
    surname: 'Gallidabino',
    role: 'Assistente dottorando - Istituto del software, USI',
    avatar: AndreaGallidabinoAvatar
  },
  {
    name: 'Monica',
    surname: 'Landoni',
    role: 'Senior Research Fellow - Facoltà di scienze informatiche, USI',
    avatar: MonicaLandoniAvatar
  },
  {
    name: 'Stefano',
    surname: 'Perregrini',
    role:
      'Architetto e Collaboratore scientifico - Accademia di architettura, USI',
    avatar: StefanoPerregriniAvatar
  }
];

const MiniHackJury = () => (
  <div id="jury" className={miniHackJuryStyles.container}>
    <Header1>Giuria</Header1>
    <div className={juryStyles.juryContainer}>
      {juryMembers
        .sort((a, b) => a.surname.localeCompare(b.surname))
        .map((member, index) => (
          <div key={index} className={juryStyles.juryMember}>
            <div
              style={{
                background: `url(${member.avatar ||
                  DefaultAvatar}) no-repeat center`,
                backgroundSize: 'cover'
              }}
              className={juryStyles.juryMemberPhoto}
            />
            <TextBold style={{ margin: 0 }}>
              {member.name} {member.surname}
            </TextBold>
            <TextSmall style={{ margin: 0 }}>{member.role}</TextSmall>
          </div>
        ))}
    </div>
  </div>
);

export default MiniHackJury;
