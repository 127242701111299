import React from 'react';

import { logoData } from '../sections/OrganizerLogos';

import organizerLogosStyles from '../sections/OrganizerLogos.module.css';
import minhackOrganizerStyles from './MiniHackOrganizers.module.css';

const miniHackLogoData = logoData.filter(data => data.id !== 'livinglab');

const MiniHackOrganizers = () => (
  <div
    className={`organizer-logo-background ${organizerLogosStyles.organizerLogoBackground}`}
  >
    {}

    <div
      className={`organizer-logo-container ${minhackOrganizerStyles.miniHackorganizerLogoContainer}`}
    >
      {miniHackLogoData.map((data, index) => (
        <a
          key={index}
          className={`organizer-logo-link ${organizerLogosStyles.organizerLogoLink}`}
          href={data.href}
          target="_blank"
          rel="noopener noreferrer"
          id={organizerLogosStyles[data.id]}
        >
          <img
            className={`organizer-logo-image ${organizerLogosStyles.organizerLogoImage}`}
            src={data.image}
            alt={data.imageAlt}
          />
        </a>
      ))}
    </div>
  </div>
);

export default MiniHackOrganizers;
