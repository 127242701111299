import React from 'react';

import {
  Emphasize,
  ExternalLinkLight,
  TextLight
} from '../components/theme/text';

import aboutStyles from '../sections/About.module.css';
import minHackAboutStyles from './MiniHackAbout.module.css';

const MiniHackAbout = () => (
  <div className={`about-background ${aboutStyles.background}`} id="about">
    <div className={`about-container ${aboutStyles.container}`}>
      <div className={`about-text-container ${aboutStyles.textContainer}`}>
        <Emphasize>Minecraft con USI Hackathon 2019</Emphasize>
        <TextLight>
          Ti piace Minecraft? Hai tra i 10 e i 15 anni? Vuoi partecipare a una
          gara di programmazione con Minecraft e vincere dei premi?
        </TextLight>
        <TextLight>
          Allora ti aspettiamo sabato 16 novembre 2019 all'Hackathon di Lugano,
          dove potrai sviluppare dei programmi per Minecraft e chi riuscirà a
          fare le costruzioni migliori, sarà premiato.
        </TextLight>
        <Emphasize>La gara</Emphasize>
        <TextLight>
          Si può partecipare da soli o in squadra con degli amici e ci saranno
          dei compiti predefiniti da svolgere. Nella prima ora di gara, per chi
          non avesse mai usato Visualmodder, daremo una spiegazione di come
          creare dei nuovi programmi, dopodiché tutte le squadre potranno
          lanciarsi nella gara.
        </TextLight>
        <Emphasize>Cosa bisogna saper fare</Emphasize>
        <TextLight>
          Bisogna conoscere Minecraft e avere conoscenze di programmazione di
          Visualmodder. Per chi non conoscesse Visualmodder faremo un'ora di
          introduzione alla programmazione e alcuni mentor sono a disposizione
          per aiutare durante la giornata. A questo indirizzo trovi spiegazioni
          e video sulla programmazione di Minecraft con Visualmodder:{' '}
          <ExternalLinkLight href="https://visualmodder.org/" target="_blank">
            visualmodder.org
          </ExternalLinkLight>
        </TextLight>
        <Emphasize>Cosa portare</Emphasize>
        <TextLight>
          Un proprio computer con Minecraft (versione Java) installato.
          Minecraft Java si può comprare sul sito{' '}
          <ExternalLinkLight
            href="https://minecraft.net/it-it/store/?ref=m"
            target="_blank"
          >
            minecraft.net
          </ExternalLinkLight>{' '}
          (Prezzo: 23.- CHF).
        </TextLight>
      </div>
      <div
        className={`about-right-background-image ${minHackAboutStyles.miniHackRightBackgroundImage}`}
      />
    </div>
  </div>
);

export default MiniHackAbout;
