import React from 'react';

import {
  Header1Light,
  TextLight,
  TextLightBold
} from '../components/theme/text';

import prizesStyle from '../sections/Prizes.module.css';
import miniHackPrizesStyle from './MiniHackPrizes.module.css';

const MiniHackPrizes = () => {
  return (
    <div className={prizesStyle.background} id="prizes">
      <div className={prizesStyle.container}>
        <Header1Light>Premi</Header1Light>
        <TextLight>
          Buoni per le prime tre squadre qualificate e premi di partecipazione
          per tutte le squadre.
          <br />
          Tutti riceveranno inoltre dei simpatici gadget ricordo dell’evento 🏆!
        </TextLight>
        <Header1Light>🎉🎉🎉</Header1Light>
        <div className={miniHackPrizesStyle.miniHackprizesTable}>
          <div />
          <TextLightBold id={prizesStyle.codingHeader}>
            Mini Hackathon
          </TextLightBold>

          <TextLight className={prizesStyle.medalText} id={prizesStyle.gold}>
            🥇
          </TextLight>
          <TextLight id={prizesStyle.goldCoding}>300 CHF</TextLight>

          <TextLight className={prizesStyle.medalText} id={prizesStyle.silver}>
            🥈
          </TextLight>
          <TextLight id={prizesStyle.silverCoding}>200 CHF</TextLight>

          <TextLight className={prizesStyle.medalText} id={prizesStyle.bronze}>
            🥉
          </TextLight>
          <TextLight id={prizesStyle.bronzeCoding}>100 CHF</TextLight>
        </div>
      </div>
    </div>
  );
};

export default MiniHackPrizes;
