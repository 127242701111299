import React, { useEffect, useState } from 'react';

import Container from '../components/theme/layout';
import { getCountdownStringFromTime } from '../util/time';

import splashStyles from '../sections/Splash.module.css';
import miniHackSplashStyles from './MiniHackSplash.module.css';

const MiniHackSplash = () => {
  const hackathonStartUTC = Date.UTC(2019, 10, 16, 10);
  const computeTimeRemaining = () => {
    const now = new Date(Date.now());
    return (
      hackathonStartUTC -
      Date.UTC(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours(),
        now.getMinutes(),
        now.getSeconds()
      )
    );
  };

  const [timeRemaining, setTimeRemaining] = useState(computeTimeRemaining());

  useEffect(() => {
    setTimeout(() => {
      setTimeRemaining(computeTimeRemaining());
    }, 1000);
  }, [timeRemaining]);

  return (
    <div
      className={`splash-background-container ${miniHackSplashStyles.splashBackgroundContainer}`}
    >
      <div className={`splash-container ${splashStyles.splashContainer}`}>
        <Container>
          <div
            className={`splash-text-container ${splashStyles.splashTextContainer}`}
          >
            <div
              className={`splash-logo-container ${splashStyles.splashLogoContainer}`}
            >
              <span
                className={`splash-logo-title ${splashStyles.splashLogoTitle}`}
              >
                USI
              </span>
              <br />
              <span
                className={`splash-logo-title ${splashStyles.splashLogoTitle}`}
              >
                MINI
              </span>
              <br />
              <span
                className={`splash-logo-title ${splashStyles.splashLogoTitle}`}
              >
                HACKATHON
              </span>
            </div>
            <h3 className={`splash-logo-info ${splashStyles.splashLogoInfo}`}>
              16 Novembre 2019 | Lugano, Svizzera
              {/* <br />
              {timeRemaining > 0
                ? getCountdownStringFromTime(timeRemaining)
                : `🎉🎉🎉 hacking on  🎉🎉🎉`} */}
            </h3>
            <div className={splashStyles.splashHackOver}>
              Hackathon finita! <br />
              Grazie a tutti per aver partecipato!
            </div>
            {/* <form
              action="https://tickets.ated.ch/event/mcuh2019/"
              method="get"
              target="_blank"
            >
              <button type="submit" className={splashStyles.registerButton}>
                REGISTRATI ORA
              </button>
            </form> */}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default MiniHackSplash;
