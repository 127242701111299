import React from 'react';

import MiniHackMenu from '../components/MiniHackMenu';
import MiniHackAbout from './MiniHackAbout';
import MiniHackFooter from './MiniHackFooter';
import MiniHackJury from './MiniHackJury';
import MiniHackLogistics from './MiniHackLogistics';
import MiniHackOrganizers from './MiniHackOrganizers';
import MiniHackPrizes from './MiniHackPrizes';
import MiniHackSplash from './MiniHackSplash';

const MiniHackMain = () => (
  <div>
    <MiniHackMenu />
    <MiniHackSplash />
    <MiniHackOrganizers />
    <MiniHackAbout />
    <MiniHackLogistics />
    <MiniHackPrizes />
    <MiniHackJury />
    <MiniHackFooter />
  </div>
);

export default MiniHackMain;
