import React from 'react';
import {
  ExternalLink,
  Header1,
  Text,
  TextBold
} from '../components/theme/text';

import textStyles from '../components/theme/text.module.css';
import scheduleStyles from '../sections/Logistics.module.css';
import miniHackLogisticsStyle from './MiniHackLogistics.module.css';

const MiniHackLogistics = () => (
  <div className={scheduleStyles.container} id="logistics">
    <Header1>Logistica</Header1>

    <div className={miniHackLogisticsStyle.miniHackTableContainer}>
      <hr className={scheduleStyles.dividerLine} id={scheduleStyles.divider1} />

      <div id={scheduleStyles.saturday}>
        <Text>
          <span className={textStyles.textBold}>Sabato</span> 16/11
        </Text>
      </div>
      <Text id={scheduleStyles.kidsTime}>10 - 16</Text>
      <div className={scheduleStyles.leftAlign} id={scheduleStyles.kids}>
        <TextBold>USI Mini Hackathon</TextBold>
      </div>

      <hr className={scheduleStyles.dividerLine} id={scheduleStyles.divider2} />
    </div>

    <div className={scheduleStyles.locationContainer}>
      <div className={scheduleStyles.mapContainer}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1385.5070443999055!2d8.95579135822274!3d46.010911094777825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47842d8a7c4dde55%3A0x166125adf2a88850!2sMain%20Lecture%20Theatre%2C%206900%20Lugano!5e0!3m2!1sen!2sch!4v1571131105180!5m2!1sen!2sch"
          style={{ border: '0', width: '100%', height: '100%' }}
        />
      </div>
      <Text>
        La USI Mini Hackathon 2019 si svolgerà nell'
        <ExternalLink
          href="https://goo.gl/maps/XWXP6Uym5jUzJf1S9"
          target="__blank"
        >
          Aula Magna del campus USI
        </ExternalLink>
      </Text>
      <Text>Via Buffi 13, 6900 Lugano, Svizzera</Text>
    </div>
  </div>
);

export default MiniHackLogistics;
