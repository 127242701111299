import React, { useEffect, useState } from 'react';

import footerStyles from '../components/Footer.module.css';
import textStyles from '../components/theme/text.module.css';

import codeLoungeLogo from '../images/logo_png_Logos for dark backgrounds_Full_logo-horizontal-white.png';

const MiniHackFooter = () => {
  const [lastModified, setLastModified] = useState<string>('not set');

  useEffect(() => {
    setLastModified(new Date(document.lastModified).toLocaleDateString());
  });

  return (
    <div className={`footer-container ${footerStyles.container}`}>
      <p className={`${textStyles.textLightSmall}`}>
        Contattaci per email:{' '}
        <a
          href="mailto:hackathon2019@usi.ch"
          className={`footer-contact-link ${textStyles.linkSmallLight}`}
        >
          hackathon2019@usi.ch
        </a>
      </p>
      <p className={`${textStyles.textLightSmall}`}>
        Utilizza la email qui sopra per ottenere un invito per il nostro canale{' '}
        <a
          href="https://usihackathon2019.slack.com"
          className={`footer-contact-link ${textStyles.linkSmallLight}`}
          target="_blank"
        >
          slack
        </a>
      </p>
      <div className={footerStyles.legalDocs}>
        <a
          href="/it/privacy-policy/"
          className={`footer-contact-link ${textStyles.linkSmallLight}`}
        >
          Privacy, elaborazione e protezione dei dati personali
        </a>
      </div>
      <p className={`${textStyles.textLightSmall}`}>
        Aggiornato al {lastModified}
      </p>
      <div className={footerStyles.poweredByLogoLink}>
        <span className={`${textStyles.textLightSmall}`}>Powered by </span>
        <a href="https://codelounge.si.usi.ch" target="_blank">
          <img src={codeLoungeLogo} height="64px" />
        </a>
      </div>
    </div>
  );
};

export default MiniHackFooter;
