import { graphql } from 'gatsby';
import React from 'react';

import { MiniHackathonSiteMetadataLookupQuery } from '../../types/graphql-types';

import './index.module.css';

import MiniHackMain from '../mini-hack/MiniHackMain';

// https://github.com/iamdustan/smoothscroll
import smoothscroll from 'smoothscroll-polyfill';
import SiteHelmet from '../components/SiteHelmet';

if (typeof window !== `undefined`) {
  // kick off the polyfill!
  smoothscroll.polyfill();
}

interface IPageProps {
  data: MiniHackathonSiteMetadataLookupQuery;
}

export const pageQuery = graphql`
  query MiniHackathonSiteMetadataLookup {
    site {
      siteMetadata {
        title
        description
        url
      }
    }
  }
`;

const IndexPage = ({ data }: IPageProps) => {
  const siteMetadata = data.site && data.site.siteMetadata;

  return (
    <React.Fragment>
      <SiteHelmet siteMetadata={siteMetadata} />
      <MiniHackMain />
    </React.Fragment>
  );
};

export default IndexPage;
